/* Basic editor styles */
.ProseMirror {
  font-size: .875rem;
  >*+* {
      margin-top: 0.75em;
  }

  img {
      max-width: 100%;
      justify-self: center;
      height: auto;
      margin-top: 1rem;
      margin-bottom: 1rem;
  
      &.ProseMirror-selectednode {
        outline: 3px solid #68CEF8;
      }
    }

  ul,
  ol {
      padding: 0 1rem;
  }

  ul li {
      list-style-type: disc;
  }

  ol li {
      list-style-type: decimal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
      line-height: 1.1;
  }

  h1 {
      display: block;
      font-size: 2em;
      margin-top: 0.67em;
      margin-bottom: 0.67em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
  }

  h2 {
      display: block;
      font-size: 1.5em;
      margin-top: 0.83em;
      margin-bottom: 0.83em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
  }

  h3 {
      display: block;
      font-size: 1.17em;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
  }

  h4 {
      display: block;
      margin-top: 1.33em;
      margin-bottom: 1.33em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
  }

  h5 {
      display: block;
      font-size: .83em;
      margin-top: 1.67em;
      margin-bottom: 1.67em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
  }

  h6 {
      display: block;
      font-size: .67em;
      margin-top: 2.33em;
      margin-bottom: 2.33em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
  }

  p {
      word-wrap: break-word;
      white-space: initial;
  }

  code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
  }

  pre {
      background: #0D0D0D;
      color: #FFF;
      font-family: 'JetBrainsMono', monospace;
      padding: 1.5rem 2rem;
      border-radius: 0.5rem;

      code {
          color: inherit;
          padding: 0;
          background: none;
          font-size: 0.8rem;
      }

      .hljs-comment,
      .hljs-quote {
          color: #616161;
      }

      .hljs-variable,
      .hljs-template-variable,
      .hljs-attribute,
      .hljs-tag,
      .hljs-name,
      .hljs-regexp,
      .hljs-link,
      .hljs-name,
      .hljs-selector-id,
      .hljs-selector-class {
          color: #f98181;
      }

      .hljs-number,
      .hljs-meta,
      .hljs-built_in,
      .hljs-builtin-name,
      .hljs-literal,
      .hljs-type,
      .hljs-params {
          color: #fbbc88;
      }

      .hljs-string,
      .hljs-symbol,
      .hljs-bullet {
          color: #b9f18d;
      }

      .hljs-title,
      .hljs-section {
          color: #faf594;
      }

      .hljs-keyword,
      .hljs-selector-tag {
          color: #70cff8;
      }

      .hljs-emphasis {
          font-style: italic;
      }

      .hljs-strong {
          font-weight: 700;
      }
  }

  img {
      max-width: 100%;
      height: auto;
  }

  blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
      border: none;
      border-top: 2px solid rgba(#0D0D0D, 0.1);
      margin: 2rem 0;
  }
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
}