html, body {
    height: fit-content;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f1f1f1;
    min-height: 100vh;
}

#root {
    height: fit-content;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-height: 100vh;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1F2937;
    border-radius: 50px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Remove citation on map */
.maplibregl-ctrl-attrib{
    display: none;
}