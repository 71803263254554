
@-webkit-keyframes fill {
  from {
      background: -webkit-linear-gradient(white, white) rgb(51, 204, 81) no-repeat 0 0;
      background: linear-gradient(white, white) rgb(51, 204, 81) no-repeat 0 0;
      background-size: 100% 100%;
  }
  to {
      background: -webkit-linear-gradient(white, white) rgb(51, 204, 81) no-repeat 0 0;
      background: linear-gradient(white, white) rgb(51, 204, 81) no-repeat 0 0;
      background-size: 100% 0;
  }
}

@keyframes fill {
  from {
      background: -webkit-linear-gradient(white, white) rgb(51, 204, 81) no-repeat 0 0;
      background: linear-gradient(white, white) rgb(51, 204, 81) no-repeat 0 0;
      background-size: 100% 100%;
  }
  to {
      background: -webkit-linear-gradient(white, white) rgb(51, 204, 81) no-repeat 0 0;
      background: linear-gradient(white, white) rgb(51, 204, 81) no-repeat 0 0;
      background-size: 100% 0;
  }
}

//The wrapper has the .cnh_holding class while the button is being pressed

.cnh_holding div {
  // background: -webkit-linear-gradient(white, white) rgb(51, 204, 81) no-repeat 0 0;
  // background: linear-gradient(white, white) rgb(51, 204, 81) no-repeat 0 0;
  mix-blend-mode: multiply;
  background-size: 100% 100%;
  -webkit-animation: fill 1.5s forwards;
  animation: fill 1.5s forwards;
}